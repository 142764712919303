<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :product-options="['oem', 'cso']"
      :headers="_headers"
      :items="sessionsList"
      show-date
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <!-- @clickOnRow="openModal" -->
      <template v-slot:severity="{ item }">
        <div>
          <v-chip :color="badgeColor(item.severity)">
            {{ item.severity }}
          </v-chip>
        </div>
      </template>
      <template v-slot:status="{ item }">
        <div>
          <v-chip
            :color="badgeColor(item.status)"
            label
            class="text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </div>
      </template>

      <template #description>
        <section>
          <v-row dense>
            <!-- Card 1 -->
            <v-col
              cols="12"
              md="4"
            >
              <router-link :to="goTo('operations_troubleshooting_detail')">
                <v-card
                  :loading="isLoading"
                  class="dashboard-card"
                >
                  <div class="cardTopBar">
                    <div class="iconBox">
                      <Icons
                        name="total-revenue"
                        class="cardIcon"
                      />
                    </div>
                    <span class="cardTitle">
                      {{ $t("faultsAndConnectivityIssues") }}
                    </span>
                    <div class="linkIconWrap">
                      <div>
                        <v-icon
                          class="mr-2"
                          small
                        >
                          mdi-eye
                        </v-icon>
                      </div>
                    </div>
                  </div>
                  <v-card-text>
                    <div class="iconWithData">
                      <p class="card-value hightlightCol">
                        {{ dashboardData.fault_connectivity_issues }}
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </router-link>
            </v-col>

            <!-- Card 2 -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <Icons
                      name="total-revenue"
                      class="cardIcon"
                    />
                  </div>
                  <span class="cardTitle">{{
                    $t("chargerServicePending")
                  }}</span>
                </div>
                <v-card-text>
                  <div class="iconWithData">
                    <p class="card-value hightlightCol">
                      <!-- {{ dashboardData.new_users }} -->
                      0
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Card 3 -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <Icons
                      name="total-revenue"
                      class="cardIcon"
                    />
                  </div>
                  <span class="cardTitle">
                    {{ $t("repair_cost") | currencyFormat(authUser) }}
                  </span>
                </div>
                <v-card-text>
                  <div class="iconWithData">
                    <p class="card-value hightlightCol">
                      <!-- <span
                        v-if="
                          !dashboardData.total_energy_consumed ||
                          dashboardData.total_energy_consumed == 'N/A'
                        "
                      >
                        {{ dashboardData.total_energy_consumed }}
                      </span>
                      <span v-else>
                        {{ dashboardData.total_energy_consumed }} kWh
                      </span> -->
                      0
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-btn-toggle
                v-model="customFilters.status"
                mandatory
                class="button-group"
                borderless
              >
                <v-btn
                  v-for="(r, i) in buttonOptions"
                  :key="i"
                  :value="r"
                  class="px-4 button"
                  :color="customFilters.status === r ? 'primary' : ''"
                  :class="{ 'active-btn': customFilters.status === r }"
                >
                  {{ r }}
                </v-btn>
              </v-btn-toggle>
              <!-- <div class="buttonGroup">
                <v-btn
                  v-for="(r, i) in buttonOptions"
                  :key="i"
                  hide-details
                >
                  {{ r }}
                </v-btn>
              </div> -->
            </v-col>
          </v-row>
        </section>
      </template>

      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <v-dialog
            v-model="showModal"
            max-width="650px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h3">Session Details</span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="modalHeaders"
                  :items="modalData"
                  hide-default-footer
                  dense
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="showModal = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    Icons: () => import("@/components/base/icons.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      //
      customFilters: {
        status: "all",
      },
      //
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        // {
        //   text: this.$t("charge_point_id"),
        //   align: "left",
        //   sortable: true,
        //   value: "charge_point_id",
        // },
        // {
        //   text: this.$t("charging_location"),
        //   align: "left",
        //   sortable: false,
        //   value: "location_name",
        // },
        // {
        //   text: this.$t("actions"),
        //   align: "left",
        //   sortable: false,
        //   value: "status",
        // },
        {
          text: "Diagnostic Code",
          align: "left",
          sortable: false,
          value: "diagnosticCode",
        },
        {
          text: "Code Description",
          align: "left",
          sortable: false,
          value: "codeDescription",
        },
        {
          text: "Severity",
          align: "left",
          sortable: false,
          value: "severity",
        },
        {
          text: this.$t("timestamp"),
          align: "left",
          sortable: true,
          value: "timestamp",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },
      ],

      // Data for modal table
      showModal: false,
      modalHeaders: [
        { text: "Car Type", value: "carType" },
        { text: "Start Time", value: "startTime" },
        { text: "End Time", value: "endTime" },
        { text: "Energy Dispensed", value: "energyDispensed" },
        { text: "Cost", value: "cost" },
      ],
      modalData: [
        {
          carType: "Audi e-tron",
          startTime: "13:50",
          endTime: "14:20",
          energyDispensed: "15 kWh",
          cost: "$12",
        },
        {
          carType: "Audi e-tron",
          startTime: "14:30",
          endTime: "14:40",
          energyDispensed: "12 kWh",
          cost: "$10",
        },
        {
          carType: "Audi e-tron",
          startTime: "14:50",
          endTime: "15:20",
          energyDispensed: "13 kWh",
          cost: "$18",
        },
        {
          carType: "Audi e-tron",
          startTime: "15:50",
          endTime: "16:20",
          energyDispensed: "11 kWh",
          cost: "$22",
        },
      ],

      //
      sessions: [
        {
          id: 1,
          diagnosticCode: "Connector Lock Failure",
          codeDescription: "Failure in locking the connector",
          status: "active",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-13 14:32:45",
          severity: "Medium",
          recommendedAction: "Check connector mechanism and reset the system",
        },
        {
          id: 2,
          diagnosticCode: "EV Communication Error",
          codeDescription: "Communication issue between the EV and the EVSE",
          status: "critical",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-13 15:45:12",
          severity: "High",
          recommendedAction:
            "Verify communication protocol and restart charging session",
        },
        {
          id: 3,
          diagnosticCode: "Ground Failure",
          codeDescription: "Grounding issue detected",
          status: "resolved",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-12 09:15:33",
          severity: "High",
          recommendedAction:
            "Inspect ground connection and consult electrician if needed",
        },
        {
          id: 4,
          diagnosticCode: "High Temperature",
          codeDescription: "Charger temperature is above safe operating limits",
          status: "pending",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-13 11:22:18",
          severity: "Medium",
          recommendedAction: "Allow system to cool down and check ventilation",
        },
        {
          id: 5,
          diagnosticCode: "Internal Error",
          codeDescription: "Internal hardware or software failure",
          status: "active",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-14 08:05:27",
          severity: "Medium",
          recommendedAction:
            "Perform system diagnostic and restart the charger",
        },
        {
          id: 6,
          diagnosticCode: "Local List Conflict",
          codeDescription: "Conflict detected in the local authorization list",
          status: "resolved",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-11 16:48:52",
          severity: "Low",
          recommendedAction:
            "Update authorization list and synchronize with central system",
        },
        {
          id: 7,
          diagnosticCode: "No Error",
          codeDescription: "Charger is functioning without issues",
          status: "resolved",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-10 10:30:15",
          severity: "None",
          recommendedAction: "No action required",
        },
        {
          id: 8,
          diagnosticCode: "Over Current Failure",
          codeDescription: "Excessive current detected",
          status: "critical",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-13 19:12:40",
          severity: "High",
          recommendedAction:
            "Disconnect immediately and inspect circuit protection",
        },
        {
          id: 9,
          diagnosticCode: "Power Meter Failure",
          codeDescription: "Issue with the power meter",
          status: "pending",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-12 13:55:22",
          severity: "Medium",
          recommendedAction:
            "Check meter connections and calibrate if necessary",
        },
        {
          id: 10,
          diagnosticCode: "Power Switch Failure",
          codeDescription: "Power switch failure detected",
          status: "active",
          chargePointId: "AB-DC-LA-1737377021",
          location: "Metro Cash & Carry, Thoker Niaz Baig",
          timestamp: "2025-03-14 07:20:11",
          severity: "High",
          recommendedAction: "Replace power switch component",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsChargingSession/getLoading",
      meta: "csmsChargingSession/getMeta",
      list: "csmsChargingSession/getList",
      dashboardData: "csmsDashboard/getList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
    buttonOptions() {
      const statuses = this.sessions.map((r) => r.status);
      const uniqueStatuses = [...new Set(statuses)].sort();
      return ["all", ...uniqueStatuses];
    },
    sessionsList() {
      const st = this.customFilters.status;
      if (st == "all") {
        return this.sessions;
      } else {
        return this.sessions.filter((r) => r.status == st);
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {},
  beforeDestroy() {
    this.$store.commit("csmsDashboard/SET_LIST", []);
    // this.$store.commit("csmsChargingSession/SET_LIST", []);

    const params = {
      name: "operations_troubleshooting",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("csmsDashboard/list", this.options);
      await this.$store.dispatch("csmsChargingSession/list", this.options);
    },
    openModal(item) {
      this.selectedSession = item; // Set the selected session data
      this.showModal = true; // Show the modal
    },
    // goToDetail() {
    //   return {
    //     name: "operations_troubleshooting_detail",
    //   };
    // },
    goTo(path) {
      return { name: path, query: this.options };
    },

    badgeColor(val) {
      if (["low", "Low", "pending"].includes(val)) {
        return "blue";
      } else if (["medium", "Medium", "active"].includes(val)) {
        return "yellow";
      } else if (["resolved"].includes(val)) {
        return "green";
      } else if (["high", "High", "critical"].includes(val)) {
        return "red";
      }
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.dashboard-card
  position: relative
  min-height: 180px
  margin: 0
  border-radius: 7px

  &::before
    content:''
    box-sizing: border-box
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 9
    z-index: 1
    background: #FFFFFF
    border: 1px solid #F3F3F3
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  &::after
    content:''
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 3px
    background: #F9F9F9
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  .cardTopBar
    width: 100%
    border-bottom: 1px solid #f3f3f3
    padding: 1.3rem 1.6rem 0.7rem
    margin-bottom: 1rem
    z-index: 2
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 62px

  .cardTitle
    font-size: 1.45rem
    color: #000
    font-family: $poppinSemi
    display: flex

  .card-value
    font-size: 1.6rem
    font-family: $poppinSemi
    z-index: 2
    margin-bottom: 0 !important
    position: relative
    color: $primaryColor

    &.hightlightCol
      color: #23BDAA

    .smallValueText
      font-size: 0.875rem
      font-family: &poppinReg

  .v-card__text
    display: flex
    flex-wrap: wrap
    padding: 2rem 1.6rem 2rem
    align-items: center

  .card-details
    z-index: 2
    position: relative
    display: flex
    flex-direction: column
    margin-left: auto

    .cardIcon
      &.increase
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #10b981

      &.decrease
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #ef4444

    span.percentText
      display: flex
      margin-top: 0.3rem
      font-size: 0.75rem
      font-family: $poppinMed

  .dateFilterCard
    display: flex
    margin-left: auto
    width:35%
    .v-input
      padding-top:0 !important
      margin-bottom: 0 !important
      margin-top:0 !important
      :deep .v-input__control
        .v-input__slot
          background: #F6F6F6
          border-radius: 6px
          padding: 0 0.8rem
          &:before
            display: none
          &:after
            display: none
          .v-text-field__slot
            input
              font-size: 0.875rem
              color: #22272E
          .v-input__append-inner
            .v-input__icon
              justify-content: flex-end
              i
                color: $primaryColor
                font-size: 1rem
  .MapLinkCard
    display: flex
    margin-left: auto
    color: #C1C7C6
    text-decoration: none
    font-family: $poppinReg
    font-size: 0.875rem
  .cardListWrap
    display: flex
    position: relative
    z-index: 2
    ul
      display: flex
      flex-wrap: wrap
      padding: 0
      margin-bottom: 1rem
      width: 100%
      overflow: hidden
      max-height: 25px
      overflow-y: auto
      li
        font-size: 1.15rem
        color: $primaryColor
        display: flex
        flex-wrap: wrap
        width: 47%
        margin-right: 3%
        margin-bottom: 0.4rem
        font-family: $poppinSemi
    &.fullWidth
      ul
        li
          width: 100%
.dashboardFilterHeader
  .headerBarWrap
    align-items: center
    padding: 6px 12px 0

  :deep .filterBtn
    display: none !important


.drawerOpen .dashboard-card .cardTitle
  font-size: 1.3rem

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
    padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 40%

.linkIconWrap
  margin-left: auto
  display: flex
  color: #000
  cursor: pointer
  i
    color: #000

.linkTextWrap
    display: flex
    color: #000
    cursor: pointer
    text-decoration: underline
    &:hover
      text-decoration: none

.iconWithData
  display: flex
  flex-direction: column
.iconBox
  background: #F4F4F4
  width: 45px
  height: 34px
  z-index: 1
  border-radius: 6px
  display: flex
  align-items: center
  justify-content: center
  margin: 0 0.75rem 0 0
  :deep .lg span svg
    fill: $primaryColor !important
    width: 28px
    height: 28px
  :deep .charging span svg
    fill: $primaryColor !important
    width: 45px
    height: 45px
  :deep .total-revenue span svg
    fill: $primaryColor !important
    width: 24px
    height: 24px
  :deep .new-users span svg
    fill: $primaryColor !important
    width: 24px
    height: 24px
  :deep .charge-point span svg
    fill: $primaryColor !important
    width: 25px
    height: 25px
    stroke: $primaryColor
  :deep .lightning-icon span svg
    fill: $primaryColor !important
    width: 54px
    height: 54px
    margin-left: -6px
  :deep .fault-and-connectivity span svg path
    fill: $primaryColor !important
    width: 54px
    height: 54px
    stroke: $primaryColor
  :deep .locations span svg
    fill: $primaryColor !important
    width: 23px
    height: 23px
    stroke: $primaryColor
  :deep .average-time-to-charge span svg
    fill: $primaryColor !important
    width: 52px
    height: 52px
    stroke: $primaryColor


:deep .blue
  background-color: #d2e3fc !important
:deep .green
  color: #00b894 !important
  background-color: #e3fcef !important
:deep .yellow
  color: #afa018 !important
  background-color: #fffacd !important
:deep .red
  color: #991b1b !important
  background-color: #ffd1d1 !important

a
  text-decoration: none

.button-group
  margin-top: 1rem
  border: 1px solid #eee
  border-radius: 4px
  // overflow: hidden
  .v-btn
    // border-radius: 0 !important
    // letter-spacing: normal !important
    border-right: 1px solid #eee !important
    // height: 36px !important
    background-color: #fff !important
    color: #000 !important
    margin-bottom:  0 !important
      // .v-btn:last-child
  //   border-right: none !important
  .active-btn
    background-color: #20a390 !important
    color: white !important




@media (min-width: 1080px) and (max-width: 1366px)
  .dashboard-card .cardTitle
    font-size: 1.15rem
  .dashboard-card .cardListWrap ul li
    font-size: 1rem
  .dashboard-card .card-value
    font-size: 1.4rem
  .drawerOpen .dashboard-card .cardTitle
    font-size: 1.15rem
  .drawerClose .dashboard-card .cardListWrap ul li
    font-size: 0.9375rem
  .drawerClose .dashboard-card .card-value
    font-size: 1.15rem
</style>
